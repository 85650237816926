.imgMultiChoices {
    filter: brightness(0) invert(1);
}

.btnMultiChoices {
    width: 120px;
    height: 50px;
}
.btnMultiChoicesLong {
    width: fit-content;
    height: 50px;
}