img{
    margin-right: 4px;
}

.imgChoices {
    filter: brightness(0) invert(1);
}

.btnChoices {
    width: 120px;
    height: 50px;
}