.images-con img {
    width: 50px;
    height: 50px;
    background: #ebebeb;
    padding: 4px;
    margin: 0 4px;
}

.imp {
    background: #c5c5c5 !important;
}