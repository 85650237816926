.skinType {
    border-radius: 4px;
    float: right;
    border: 2px solid !important;
    display: block;
    width: 200px;
}
.analyses{
    scroll-margin-top: 150px;
    border: 0 !important;
}
.analyse-btn {
    min-width: 100%  !important;
    font-size: 16px !important;
}

.analyses-next {
    margin: 35px auto 0 !important;
    color: orange !important;
    padding: 12px 7px !important;
    display: block !important;
}

#my-type {
    margin-top: 20px;
    border-radius: 13px !important;
    background-color: #F4F4F4 !important;
    box-shadow: 6px 6px 7px 0 rgba(174, 174, 192, 0.40), -6px -6px 7px 0 #FFF;
    color: #5B6577 !important;
    font-size: 18px !important;
    font-family: Raleway, sans-serif;
    line-height: 28px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    padding: 20px 16px;
}
